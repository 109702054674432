@font-face {
  font-family: "SalesforceSans";
  src: url("SalesforceSans-Bold.woff2") format("woff2"),
    url("SalesforceSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "SalesforceSans";
  src: url("SalesforceSans-Regular.woff2") format("woff2"),
    url("SalesforceSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
